import React, { useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { SHA256, enc } from "crypto-js";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Modal from "@mui/material/Modal";
import * as XLSX from "xlsx";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

const FormComponent = ({ setSnackOpen, setMessageOpen, setSeverity }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [currency, setCurrency] = useState("");
  const [holding, setHolding] = useState("");
  const [holdingId, setHoldingId] = useState("");
  const [group, setGroup] = useState("");
  const [groupId, setGroupId] = useState("");
  const [name, setName] = useState("");
  const [store, setStore] = useState("");
  const [address, setAddress] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [city, setCity] = useState("");
  const [merchantState, setMerchantState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [mccID, setMccID] = useState("");
  const [merchantID, setMerchantID] = useState("");
  const [amountOther, setAmountOther] = useState("");
  const [terminals, setTerminals] = useState([
    { terminalID: "", terminalType: "", terminalSerial: "" },
  ]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [validationResults, setValidationResults] = useState([]);
  const [file, setFile] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [dataProcessed, setDataProcessed] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const url = "https://blokko.app/api-gateway/br01/enrollment";
      let data = {
        version: "04.08.03",
        acquirer: {
          merchantHolding: holding,
          merchantHoldingID: holdingId,
          merchantGroup: group,
          merchantGroupID: groupId,
          merchantName: name,
          merchantStore: store,
          merchantAddress: address,
          merchantNeighborhood: neighborhood,
          merchantCity: city,
          merchantState: merchantState,
          merchantPostalCode: postalCode,
          mccID: mccID,
          merchantID: merchantID,
          enableAmountOther: amountOther,
          timeStamp: new Date().toISOString(),
          userToken: "",
          userID: localStorage.getItem("email"),
          terminal: {
            terminals: terminals,
          },
        },
        fiat: { currencyCode: currency },
        reqhashMAC: "",
      };
      const dataString = JSON.stringify(data);
      const hash = SHA256(dataString + token).toString(enc.Base64);
      const userHash = SHA256(dataString).toString(enc.Base64);
      data.reqhashMAC = hash;
      data.userToken = userHash;

      const response = await axios.post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          acquirerName: localStorage.getItem("acquirerName"),
          acquirerID: localStorage.getItem("acquirerID"),
        },
      });
      setSnackOpen(response.data?.holdings?.holding.length > 0 ? false : true);
      setMessageOpen("Merchant Terminal successfully saved!");
      setSeverity("success");
    } catch (error) {
      console.error(error);
      setSeverity("error");
      setMessageOpen("Merchant Terminal NOT saved!");
      setSnackOpen(true);
    }
  };

  const handleHoldingChange = (event) => {
    const data = event.target.value;
    setHolding(data);
  };

  const handleHoldingIdChange = (event) => {
    const data = event.target.value;
    setHoldingId(data);
  };

  const handleGroupChange = (event) => {
    const data = event.target.value;
    setGroup(data);
  };

  const handleGroupIdChange = (event) => {
    const data = event.target.value;
    setGroupId(data);
  };

  const handleNameChange = (event) => {
    const data = event.target.value;
    setName(data);
  };

  const handleStoreChange = (event) => {
    const data = event.target.value;
    setStore(data);
  };

  const handleAddressChange = (event) => {
    const data = event.target.value;
    setAddress(data);
  };

  const handleNeighborhoodChange = (event) => {
    const data = event.target.value;
    setNeighborhood(data);
  };

  const handleCityChange = (event) => {
    const data = event.target.value;
    setCity(data);
  };

  const handleMerchantStateChange = (event) => {
    const data = event.target.value;
    setMerchantState(data);
  };

  const handlePostalCodeChange = (event) => {
    const data = event.target.value;
    setPostalCode(data);
  };

  const handleMccIDChange = (event) => {
    const data = event.target.value;
    setMccID(data);
  };

  const handleMerchantIDChange = (event) => {
    const data = event.target.value;
    setMerchantID(data);
  };

  const handleAmountOtherChange = (event) => {
    const data = event.target.value;
    setAmountOther(data);
  };

  const handleTerminalChange = (index, field, value) => {
    const updatedTerminals = [...terminals];
    updatedTerminals[index][field] = value;
    setTerminals(updatedTerminals);
  };

  const handleAddTerminal = () => {
    setTerminals([
      ...terminals,
      { terminalID: "", terminalType: "", terminalSerial: "" },
    ]);
  };

  const handleDeleteTerminal = (index) => {
    if (terminals.length > 1) {
      const updatedTerminals = terminals.filter((_, i) => i !== index);
      setTerminals(updatedTerminals);
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const processFileUpload = () => {
    const reader = new FileReader();
    let filteredCellData = [];

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const cellData = XLSX.utils.sheet_to_json(sheet, {
        header: 1,
        blankrows: true,
        defval: "",
      });

      filteredCellData = cellData.filter(
        (row) => !row.every((cell) => cell === "")
      );
      filteredCellData.shift();
      const isValid = validateCellData(filteredCellData);
      if (isValid.length === 0) {
        sendData(filteredCellData);
      }
      setValidationResults(isValid);
      setIsModalOpen(true);
    };

    // Clear the file input before starting file reading
    setFile(null);

    // Ensure file is available before trying to read it
    if (file) {
      reader.readAsArrayBuffer(file);
    }
  };

  const validateCellData = (data) => {
    const expectedLengths = {
      Holding: 16,
      HoldingID: 16,
      Group: 16,
      GroupID: 16,
      "Merchant Name": 16,
      Store: 8,
      Address: 32,
      Neighborhood: 32,
      City: 32,
      State: 32,
      "Postal Code": 16,
      mccID: 4,
      MerchantID: 16,
      "Amount Other": 5,
      "Currency Code": 3,
    };
    const mandatoryFields = [
      "Merchant Name",
      "Address",
      "City",
      "State",
      "Postal Code",
      "mccID",
      "MerchantID",
      "Amount Other",
      "Currency Code",
    ];
    const validationResults = [];
    // console.log(data.length)
    // console.log(data)
    for (let i = 1; i < data.length; i++) {
      const row = data[i];
      // console.log(row)
      let validation = { row: i, validation: "", field: "", message: "" };
      for (let j = 0; j < row.length; j++) {
        const field = data[0][j];
        const value = row[j];
        const expectedLength = expectedLengths[field];
        // console.log(field," ",value.length," ",expectedLength)
        if (value.length > expectedLength) {
          validation = {};
          validation.row = i + 2;
          validation.validation = "F";
          validation.field = field;
          validation.message = `Invalid length. Expected ${expectedLength} characters`;
          validationResults.push(validation);
        }
        // console.log(value)
        if (mandatoryFields.includes(field) && value.trim() === "") {
          validation = {};
          validation.row = i + 2;
          validation.validation = "F";
          validation.field = field;
          validation.message = " Mandatory field";
          validationResults.push(validation);
        }
      }
    }
    // const response = validationResults.filter(
    //   (obj, index, self) =>
    //     index === self.findIndex((o) => o.field === obj.field)
    // );
    const response = validationResults;
    setDataProcessed(data);
    const newData = data.map((row) => {
      // Create a shallow copy of the row array
      const newRow = [...row];
      // Apply splice operation on the copied row
      newRow.splice(0, 15);
      return newRow; // Return the modified row
    });
    const errors = [];
    for (let i = 0; i < newData[0].length; i += 3) {
      for (let j = 1; j < newData.length; j++) {
        if (
          newData[j][i] === "" &&
          newData[j][i + 1] === "" &&
          newData[j][i + 2] === ""
        ) {
          continue;
        } else if (
          newData[j][i] === "" ||
          newData[j][i + 1] === "" ||
          newData[j][i + 2] === ""
        ) {
          errors.push(
            `Error on row ${j + 2} columns ${
              newData[0][i]
            }, because one or more fields are empty.`
          );
        }
      }
      // console.log(newData);
      const terminalID = newData[1][i];
      const terminalType = newData[1][i + 1];
      const terminalSerial = newData[1][i + 2];
      if (terminalID.length > 16) {
        errors.push(
          `Error on row ${i + 3} column "${
            newData[0][i]
          }" because the length is >16.`
        );
      }
      if (terminalType.length > 1) {
        errors.push(
          `Error on row ${i + 3} column "${
            newData[0][i + 1]
          }" because the length is >1.`
        );
      }
      if (terminalSerial.length > 16) {
        errors.push(
          `Error on row ${i + 3} column "${
            newData[0][i + 2]
          }" because the length is >16.`
        );
      }
    }
    if (errors.length > 0) {
      const errorMessage = errors.map((error) => `${error}`).join("\n");
      response.push({
        row: "-",
        validation: "F",
        field: "Terminals",
        message: errorMessage,
      });
    }
    return response;
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const sendData = async (data) => {
    const jsonObjects = [];
    const errors = [];
    const token = localStorage.getItem("token");
    const url = "https://blokko.app/api-gateway/br01/enrollment";

    // Iterate over data rows starting from index 1 (excluding header)
    for (let j = 1; j < data.length; j++) {
      const [
        holding,
        holdingId,
        group,
        groupId,
        name,
        store,
        address,
        neighborhood,
        city,
        merchantState,
        postalCode,
        mccID,
        merchantID,
        amountOther,
        currency,
        ...terminalFields // Extracting all terminal fields dynamically
      ] = data[j]; // Extracting data from the second array (data array)

      // Constructing the terminals array
      const terminals = [];

      // Loop through terminal fields in groups of three
      for (let i = 0; i < terminalFields.length; i += 3) {
        const terminalID = terminalFields[i];
        const terminalType = terminalFields[i + 1];
        const terminalSerial = terminalFields[i + 2];

        // Check if all fields of the terminal are not empty
        if (terminalID !== "" && terminalType !== "" && terminalSerial !== "") {
          // Add terminal to the terminals array
          terminals.push({
            terminalID: terminalID,
            terminalType: terminalType,
            terminalSerial: terminalSerial,
          });
        }
      }

      // Constructing the data object
      const dataJson = {
        version: "04.08.03",
        acquirer: {
          merchantHolding: holding,
          merchantHoldingID: holdingId,
          merchantGroup: group,
          merchantGroupID: groupId,
          merchantName: name,
          merchantStore: store,
          merchantAddress: address,
          merchantNeighborhood: neighborhood,
          merchantCity: city,
          merchantState: merchantState,
          merchantPostalCode: postalCode,
          mccID: mccID,
          merchantID: merchantID,
          enableAmountOther: amountOther,
          timeStamp: new Date().toISOString(),
          userToken: "",
          userID: localStorage.getItem("email"),
          terminal: {
            terminals: terminals,
          },
        },
        fiat: { currencyCode: currency },
        reqhashMAC: "",
      };

      const dataString = JSON.stringify(dataJson);
      const hash = SHA256(dataString + token).toString(enc.Base64);
      const userHash = SHA256(dataString).toString(enc.Base64);
      dataJson.reqhashMAC = hash;
      dataJson.acquirer.userToken = userHash;

      try {
        const response = await axios.post(url, dataJson, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            acquirerName: localStorage.getItem("acquirerName"),
            acquirerID: localStorage.getItem("acquirerID"),
          },
        });
        jsonObjects.push(dataJson);
      } catch (error) {
        errors.push(error);
        // console.error(error);
      }
    }
    console.log(errors);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {validationResults.length === 0 ? (
          <Box
            sx={{
              backgroundColor: "white",
              padding: 4,
              maxWidth: 600,
              maxHeight: "80vh",
              overflowY: "auto",
              borderRadius: 3,
            }}
          >
            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              Validating file structure
            </Typography>
            <div style={{ textAlign: "center" }}>
              <TaskAltIcon sx={{ fontSize: 50, color: "green" }} />
            </div>
            <Typography variant="h6" gutterBottom sx={{ textAlign: "center" }}>
              No errors found
            </Typography>
            <Box sx={{ marginTop: 2, textAlign: "center" }}>
              <Button onClick={handleCloseModal} variant="contained">
                Ok
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: "white",
              padding: 4,
              maxWidth: 600,
              maxHeight: "80vh",
              overflowY: "auto",
              borderRadius: 3,
            }}
          >
            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              Validating file structure
            </Typography>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Validation</TableCell>
                    <TableCell>Row</TableCell>
                    <TableCell>Field</TableCell>
                    <TableCell>Error</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {validationResults.map((row) => (
                    <TableRow key={row.field}>
                      <TableCell component="th" scope="row">
                        {row.validation === "T" ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <HighlightOffIcon color="error" />
                        )}
                      </TableCell>
                      <TableCell>{row.row}</TableCell>
                      <TableCell>{row.field}</TableCell>
                      <TableCell>
                        <ul>
                          {row.message
                            .split("\n")
                            .map((errorMessage, index) => (
                              <li key={index}>{errorMessage}</li>
                            ))}
                        </ul>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ marginTop: 2, textAlign: "center" }}>
              <Button onClick={handleCloseModal} variant="contained">
                Close
              </Button>
            </Box>
          </Box>
        )}
      </Modal>
      <Grid
        container
        spacing={isMobile ? 2 : 4}
        pb={isMobile ? "16px" : "32px"}
      >
        <Grid item xs={isMobile ? 12 : 3}>
          <FormControl fullWidth>
            <InputLabel id="currency-label">Currency Code</InputLabel>
            <Select
              labelId="currency-label"
              id="currency"
              value={currency}
              label="Currency Code"
              onChange={(event) => setCurrency(event.target.value)}
              required
            >
              <MenuItem value="BRL">BRL</MenuItem>
              <MenuItem value="USD">USD</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={isMobile ? 12 : 3}>
          <TextField
            fullWidth
            id="mccID"
            label="MCC ID"
            value={mccID}
            onChange={handleMccIDChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 4,
            }}
            required
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 3}>
          <FormControl fullWidth>
            <InputLabel id="amountOther-label">Amount Other</InputLabel>
            <Select
              labelId="amountOther-label"
              id="amountOther"
              value={amountOther}
              label="Amount Other"
              onChange={handleAmountOtherChange}
              required
            >
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={isMobile ? 12 : 3} sx={{ textAlign: "center" }}>
          {!file && (
            <>
              <input
                accept=".xls, .xlsx"
                style={{ display: "none" }}
                id="contained-button-file"
                type="file"
                onChange={handleFileChange}
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" component="span">
                  Upload Excel File
                </Button>
              </label>
            </>
          )}
          {/* Render a button to process the file upload */}
          {file && (
            <Button variant="contained" onClick={processFileUpload}>
              Process Upload
            </Button>
          )}
          <div>
            <a
              id="template"
              key={"template"}
              href={`${process.env.PUBLIC_URL}/Merchant & Terminal.xlsx`}
              download
            >
              Download Template
            </a>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={isMobile ? 2 : 4}>
        <Grid item xs={isMobile ? 12 : 3}>
          <TextField
            fullWidth
            id="holding"
            label="Holding"
            value={holding}
            onChange={handleHoldingChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 16,
            }}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 3}>
          <TextField
            fullWidth
            id="holdingId"
            label="HoldingID"
            value={holdingId}
            onChange={handleHoldingIdChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 16,
            }}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 3}>
          <TextField
            fullWidth
            id="group"
            label="Group"
            value={group}
            onChange={handleGroupChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 16,
            }}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 3}>
          <TextField
            fullWidth
            id="groupId"
            label="GroupID"
            value={groupId}
            onChange={handleGroupIdChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 16,
            }}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 3}>
          <TextField
            fullWidth
            id="name"
            label="Merchant Name"
            value={name}
            onChange={handleNameChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 16,
            }}
            required
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 3}>
          <TextField
            fullWidth
            id="store"
            label="Store"
            value={store}
            onChange={handleStoreChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 8,
            }}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 3}>
          <TextField
            fullWidth
            id="address"
            label="Address"
            value={address}
            onChange={handleAddressChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 32,
            }}
            required
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 3}>
          <TextField
            fullWidth
            id="merchantID"
            label="Merchant ID"
            value={merchantID}
            onChange={handleMerchantIDChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 16,
            }}
            required
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 3}>
          <TextField
            fullWidth
            id="neighborhood"
            label="Neighborhood"
            value={neighborhood}
            onChange={handleNeighborhoodChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 32,
            }}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 3}>
          <TextField
            fullWidth
            id="city"
            label="City"
            value={city}
            onChange={handleCityChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 32,
            }}
            required
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 3}>
          <TextField
            fullWidth
            id="merchantState"
            label="State"
            value={merchantState}
            onChange={handleMerchantStateChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 32,
            }}
            required
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 3}>
          <TextField
            fullWidth
            id="postalCode"
            label="Postal Code"
            value={postalCode}
            onChange={handlePostalCodeChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 16,
            }}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Divider variant="middle" component="li">
            <Chip variant="outlined" color="info" label="TERMINALS" />
          </Divider>
        </Grid>
        {terminals.map((terminal, index) => (
          <Grid item xs={10}>
            <Grid container spacing={3} key={index}>
              <Grid item xs={3}>
                <TextField
                  label="Terminal ID"
                  value={terminal.terminalID}
                  onChange={(e) =>
                    handleTerminalChange(index, "terminalID", e.target.value)
                  }
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="terminal-tpe-label">Terminal Type</InputLabel>
                  <Select
                    labelId="terminal-type-label"
                    value={terminal.terminalType}
                    label="Terminal Type"
                    onChange={(e) =>
                      handleTerminalChange(
                        index,
                        "terminalType",
                        e.target.value
                      )
                    }
                    required
                  >
                    <MenuItem value="A">ATM = A</MenuItem>
                    <MenuItem value="C">COTS (Phone/Tablet) = C</MenuItem>
                    <MenuItem value="E">eCommerce = E</MenuItem>
                    <MenuItem value="R">ECR = R</MenuItem>
                    <MenuItem value="I">App = I</MenuItem>
                    <MenuItem value="K">Kiosk = K</MenuItem>
                    <MenuItem value="M">mPOS = M</MenuItem>
                    <MenuItem value="P">POS = P</MenuItem>
                    <MenuItem value="T">Teller = T</MenuItem>
                    <MenuItem value="V">Vending Machine = V</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Terminal Serial"
                  value={terminal.terminalSerial}
                  onChange={(e) =>
                    handleTerminalChange(
                      index,
                      "terminalSerial",
                      e.target.value
                    )
                  }
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={3} sx={{ alignSelf: "center" }}>
                <IconButton
                  onClick={() => handleDeleteTerminal(index)}
                  disabled={terminals.length === 1}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        ))}
        <Grid item xs={2} sx={{ alignSelf: "center" }}>
          <Button variant="contained" onClick={handleAddTerminal}>
            Add Terminal
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default FormComponent;
